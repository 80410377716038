.navbar {
  background-color: black;
  z-index: 5;
}
.nav-items {
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.nav-item {
  color: white;
  font-size: 0.9em;
}
.nav-item:hover {
  color: #ff0000;
}
.quiz-heading {
  color: #004dac;
  font-size: 1.5rem;
  font-weight: 600;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-heading {
  color: #ffffff;
  cursor: pointer;
}
.dropdown-heading:hover {
  color: #ff0000;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  border-radius: 5px;
  z-index: 5;
}
.dropdown-content-list {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.dropdown-content a {
  color: #ff0000;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ff0000;
  color: #ffffff;
  transition: 0s;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.navbar-big-screens {
  display: block;
}

.navbar-small-screens {
  display: none;
}

@media screen and (max-width: 1500px) {
  .nav-items {
    gap: 1.5em;
  }
  .nav-item {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1300px) {
  .nav-item {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-big-screens {
    display: none;
  }

  .navbar-small-screens {
    display: block;
  }

  .navbar-open {
    width: 100%;
  }

  .navbar-off {
    width: 0%;
    padding: 0%;
  }

  .mobile-sidenavbar {
    height: 100vh;
    background-color: black;
    color: #ffffff;
    position: fixed;
    top: 0%;
    z-index: 100;
    overflow-x: scroll;
    right: 0%;
    transition: 0.2s;
  }

  .btn-mobile-navbar {
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mob-nav-hr {
    color: #ff0000;
  }
}
