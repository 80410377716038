/* .parameter-selection
  .splide
  .splide__track
  .splide__list
  .splide__slide */
.parameter-active {
  border: 1px solid #ff0000 !important;
}

.component-card {
  padding: 1em;
  min-width: 250px;
  max-width: 250px;
  flex: 0 0 250px;
  border: 3px solid white;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.selected {
  border: 3px solid #ff0000;
}

.selected-text {
  color: #ff0000;
}

.parameter-list {
  display: flex;
  justify-content: flex-start;
  gap: 2em;
  overflow-x: auto;
  white-space: nowrap;
}

.component-image {
  background-color: white;
  border-radius: 10px;
}

.div-display {
  width: 30%;
}

.final-custom-image {
  width: 35%;
}

@media screen and (max-width: 640px) {
  .active-image {
    width: 33%;
  }
  .display-custom-img {
    max-width: 20px;
  }
  .display-final {
    flex-direction: column;
  }

  .final-custom-image {
    width: 100%;
  }
  .div-display {
    width: 100%;
  }
  .component-card {
    min-width: 200px;
    max-width: 200px;
  }

  .component-text {
    font-size: 0.8em;
  }
}
