/* .parameter-selection
  .splide
  .splide__track
  .splide__list
  .splide__slide */
.parameter-active {
  border: 1px solid #ff0000 !important;
}

.component-card {
  padding: 1em;
  min-width: 170px;
  max-width: 170px;
  flex: 0 0 250px;
  border: 3px solid white;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.selected {
  border: 3px solid #ff0000;
}

.selected-text {
  color: #ff0000;
}

.parameter-list {
  display: flex;
  gap: 2em;
  overflow-x: auto;
  justify-content: center;
  white-space: nowrap; /* Default alignment */
}

.parameter-list.overflowing {
  justify-content: flex-start; /* Alignment when overflowing */
}

.component-image {
  background-color: white;
  border-radius: 10px;
}

.div-display {
  width: 30%;
}

.final-custom-image {
  width: 35%;
}

.rating-input div {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 70px;
  height: 70px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 640px) {
  .active-image {
    width: 33%;
  }
  .display-custom-img {
    max-width: 20px;
  }
  .display-final {
    flex-direction: column;
  }

  .final-custom-image {
    width: 100%;
  }
  .div-display {
    width: 80%;
  }
  .component-card {
    min-width: 120px;
    max-width: 120px;
  }

  .component-text {
    font-size: 0.8em;
  }
  .rating-input div {
    width: 60px;
    height: 60px;
  }
}
