.about-us-banner-heading {
  top: 5%;
  left: 4%;
}

.banner-text {
  font-size: 0.9em;
}

.rajib-banner-content {
  padding-right: 2.5em;
  top: 45%;
  left: 4%;
}

.rajib-banner-content .rajib-banner-text {
  font-size: 1.3em;
}

.container-banner-content {
  padding-right: 20em;
  top: 35%;
  left: 4%;
}

.container-banner-content .container-banner-text {
  font-size: 1.3em;
}

.about-us-banner-heading .banner-heading {
  border-bottom: 2px solid #ff0000;
}

.overlay-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1500px) {
  .rajib-banner-content .rajib-banner-text {
    font-size: 1.15em;
  }

  .container-banner-content .container-banner-text {
    font-size: 1.15em;
  }
}

@media screen and (max-width: 700px) {
  .about-us-banner-heading {
    top: 20%;
    left: 43%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    /* text-align: center; */
  }

  .banner-text {
    font-size: 0.8em;
    /* text-align: center; */
  }

  .rajib-banner-content {
    padding-right: 1em;
    top: 50%;
    left: 4%;
  }

  .rajib-banner-content h2 {
    font-size: 1.2em;
  }
  .rajib-banner-text {
    display: none;
  }
}
