.e-bikes-div {
  gap: 1em;
}

.e-bikes-category-cards {
  border: 0.5px solid grey;
}

.e-bikes-category-cards:hover {
  opacity: 0.6;
}

@media screen and (max-width: 640px) {
  .e-bikes-div {
    flex-direction: column;
    gap: 2em;
  }
}
