@font-face {
  font-family: "Metropolis-Regular";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Medium.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Metropolis-Bold.otf")
    format("opentype");
  font-weight: bolder;
  font-style: normal;
}

.btn-whatsapp-pulse {
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse-border {
  bottom: 120px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

body {
  padding: 0%;
  margin: 0%;
  font-family: "Metropolis-Regular", sans-serif;
}

.logo {
  width: 200px;
}

.primary-button {
  border: none;
  color: white;
  background-color: #ff0000;
  text-align: center;
  font-size: 0.9em;
  padding: 0.6rem 1.3rem;
  border: 1px solid #ff0000;
}

.calendly-button {
  border: none;
  color: white;
  background-color: #006bff;
  text-align: center;
  font-size: 0.9em;
  padding: 0.6rem 1.3rem;
  border: 2px solid #006bff;
}

.selection-button {
  border: none;
  color: white;
  background-color: #ff0000;
  text-align: center;
  font-size: 0.9em;
  padding: 0.6rem 1.3rem;
  border: 2px solid #ff0000;
}

.primary-button:hover {
  color: white;
  background-color: black;
  border: 1px solid white;
}

.calendly-button:hover {
  color: #006bff;
  background-color: white;
}

.secondary-button {
  color: black;
  background-color: white;
  text-align: center;
  font-size: 0.9em;
  padding: 0.6rem 1.3rem;
}

.secondary-button:hover {
  color: white;
  background-color: #ff0000;
}

.dynem-color {
  color: #ff0000;
}

.dynem-bg {
  background-color: black;
}

.dynem-bg-secondary {
  background-color: #151a20;
}

.h2-headings {
  font-size: 1.5em;
}

.dts-content {
  height: 260px;
}

.category-cards:hover h1 {
  color: #ff0000;
}

.wa-chat-btn-fixed-wrap {
  position: fixed;
  display: block;
  z-index: 5;
  right: 2%;
  bottom: 5%;
}

.wa-chat-btn-icon-image {
  width: 60px;
  cursor: pointer;
}

.splide__arrow {
  background-color: #ffffff !important;
}

.splide__arrow svg {
  fill: #ff0000 !important;
  height: 2em !important;
}

.splide__arrow--prev {
  left: -3em !important;
}

.splide__arrow--next {
  right: -3em !important;
}

.em-btn-1 {
  background-color: black;
  color: #ffffff;
  border-radius: 25px;
  padding: 0.5em 2em;
  border: none;
}

.em-btn-2 {
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 0.5em 2em;
  border: none;
}

.dynem-bg-color {
  background-color: #ff0000;
}

@media screen and (max-width: 1386px) {
  .dts-content {
    height: 290px;
  }
}

@media screen and (max-width: 640px) {
  .logo {
    width: 170px;
  }

  .primary-button,
  .secondary-button,
  .selection-button {
    font-size: 0.9em;
    padding: 0.7rem;
  }

  .dts-content {
    height: fit-content;
  }

  .wa-chat-btn-icon-image {
    width: 55px;
    cursor: pointer;
  }

  .wa-chat-btn-fixed-wrap {
    position: fixed;
    display: block;
    right: 3%;
    z-index: 5;
  }
}
