.sidebar {
  min-width: 30%;
  height: 100vh;
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 10em;
  font-size: 25px;
}

table,
td,
tr {
  padding: 0.2em 1.3em;
  border: 1px solid black;
}

td {
  font-size: 0.65em;
}

.related-blogs h5 {
  font-family: "Metropolis-Regular";
  font-size: 1.2em;
}

@media screen and (max-width: 640px) {
  td {
    font-size: 0.8em;
  }
}
