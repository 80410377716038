.thankyou-div {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, 30%);
}

.thankyou {
  font-size: 3em;
  font-weight: 900;

  margin: 0px;
  text-transform: uppercase;
  color: white;
  margin-top: 1em;

  z-index: -1;
}
.go-back-link {
  margin-top: 5em;
  display: block;
  text-decoration: none;
  font-size: 1.3em;
  font-weight: 900;
  color: #ff0000;
}

.go-back-link:hover{
    color: #ff0000;
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .thankyou-div {
    position: relative;
    top: 20%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, 15%);
  }
  .thankyou-div h4 {
    font-weight: 600;
    margin-top: 2em;
  }
  .thankyou-div h6 {
    line-height: 1.5em;
    margin-top: 2em;
  }
  .thankyou {
    font-size: 2em;
  }
}
