.get-quote-home-banner {
  top: 20%;
  /* right: 10%; */
}

.tablet-view {
  display: none;
}

.react-player__shadow {
  background: none !important;
  background-color: #ff0000 !important;
  width: 45px !important;
  height: 45px !important;
}

.react-player__play-icon {
  border-width: 10px 0px 10px 19px !important;
}

.hero-banner-button-1 {
  display: block;
  top: 63%;
  left: 7.3%;
}

.hero-banner-button {
  display: block;
  top: 63%;
  left: 8.2%;
}

.hero-banner-button-mobile {
  display: none;
}

.hero-banner-button-mobile .logos-section {
  background-color: #151a20;
}

.usp-section {
  align-items: center;
}

.usp-item {
  border-left: 5px solid #ff0000;
  padding-left: 0.5em;
}

.news-logos .splide__list {
  align-items: center;
}

.why-dynem-heading {
  top: 7%;
  left: 2.3%;
}

.usp-why {
  margin-bottom: 10em;
}

.bike-categories {
  background-color: black;
}

.why-dynem-usp {
  top: 20%;
  left: 0%;
}

.category-heading {
  font-size: 1.3em;
}

.category-card a:hover img {
  opacity: 0.6;
  transform: scale(1.05);
}

.category-card:hover p {
  color: #ff0000;
}

.splide__pagination {
  position: static !important;
  margin-top: 10px !important;
}

.splide__pagination__page.is-active {
  background: #ff0000 !important;
}

.gigafactory-banner-content {
  padding-right: 2em;
  top: 7%;
  left: 4%;
}

.founder-name {
  font-size: 1.3em;
}

.founder-text {
  height: 340px;
}

.line-break {
  background-color: #ff0000;
  height: 1em;
}

.factory-text {
  line-height: 200%;
  font-size: 1.1em;
}

.company-logos {
  background-color: #f6f6f6;
}

.dynem-background {
  background-color: #ff0000;
}

.accordion-item {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #e5e4e2;
}

.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.accordion-button {
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.faq-home .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ff0000' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e") !important;
}

.accordion-body {
  font-size: 0.9em;
  padding: 0px;
  padding-bottom: 1em;
}

.h1-home {
  width: fit-content;
  border-bottom: #ff0000 1px solid;
}

/* .amigo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(128, 128, 128, 0.4),
    rgba(128, 128, 128, 0.4)
  );
  pointer-events: none; 
} */

@media screen and (max-width: 1536px) {
  .get-quote-home-banner {
    top: 12%;
  }
  .why-dynem-heading {
    left: 3%;
  }

  .usp-why {
    margin-bottom: 7em;
  }

  .founder-text {
    height: 400px;
  }
  .why-dynem-usp {
    top: 17%;
  }

  .factory-text {
    line-height: 200%;
    font-size: 1em;
  }
}

@media screen and (max-width: 1408px) {
  .why-dynem-heading {
    left: 3.3%;
  }

  .usp-why {
    margin-bottom: 6em;
  }

  .why-dynem-usp {
    top: 15%;
  }
}

@media (min-width: 750px) and (max-width: 1024px) {
  .bigger-screen {
    display: none;
  }
  .tablet-view {
    display: block;
  }
  .smaller-screen {
    display: none;
  }
  .tablet-display-off {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .feature-text h1 {
    font-size: 1.2em;
    font-weight: bold;
  }
  .get-quote-home-banner {
    top: 8%;
    left: 8%;
  }
  .tablet-view {
    display: none;
  }
  .h1-home {
    font-size: 1.5em;
  }
  .usp-section {
    align-items: normal;
    flex-direction: column;
  }

  .hero-banner-button-mobile {
    display: block;
    top: 40%;
    left: 11%;
  }

  .hero-banner-button {
    display: none;
  }

  .hero-banner-button-1 {
    display: none;
  }

  .last-logo {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  .why-dynem-heading {
    top: 5%;
    left: 4%;
    font-size: 1.6em;
  }

  .why-dynem-usp {
    top: 15%;
    left: -1.5%;
  }

  .usp-why h2 {
    font-size: 1em;
  }

  .usp-why p {
    font-size: 0.85em;
    margin: 0;
  }

  .usp-why {
    margin-bottom: 3em;
  }

  .bike-heading-mobile {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
  }

  .bike-heading-mobile h2 {
    font-size: 0.85em;
  }

  .bike-img::after {
    content: "";
    position: absolute;
    bottom: -550%;
    left: 0;
    width: 100%;
    height: 200%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.7)
    );
  }

  .gigafactory-banner-content {
    padding-right: 1em;
    top: 5%;
    left: 4%;
  }

  .gigafactory-banner-heading {
    font-size: 1.2em;
  }

  .factory-text {
    line-height: 130%;
  }

  .founder-text {
    font-size: 0.85em;
    height: 330px;
  }
  .founder-text p {
    font-size: 0.85em;
  }
  .faq-home .accordion-body {
    font-size: 0.9em;
  }
  .faq-home .accordion-button {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 320px) {
  .founder-text {
    font-size: 0.85em;
    height: 400px;
  }
}
