@media screen and (min-width: 640px) {
  .key-points {
    font-size: 1.3em;
  }
  .bn45 {
    width: 200px;
  }
  .bn46 {
    width: 200px;
    height: 60px;
  }
  .text-width-features {
    width: 50%;
  }
  .feature-text {
    text-align: left !important;
  }
}

.feature-btn-width {
  width: fit-content !important;
}
.get-started {
  background-color: #373737;
}

.bn45,
.bn46 {
  width: 170px;
  height: 50px;
}

.feature-text {
  text-align: center;
}
