@font-face {
  font-family: "Poppins-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Regular.ttf")
    format("");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Light.ttf")
    format("opentype");
  font-weight: bolder;
  font-style: normal;
}

.get-quote-landing .splide__pagination__page.is-active {
  background-color: #ff0000;
}

.get-quote-landing .splide__pagination {
  bottom: -1.5em;
}

.poppins-medium {
  font-family: "Poppins-Medium" !important;
}

.poppins-bold {
  font-family: "Poppins-Bold" !important;
}

.poppins-regular {
  font-family: "Poppins-Regular" !important;
}

.poppins-light {
  font-family: "Poppins-Light" !important;
}

.get-quote-landing {
  font-family: "Poppins-Light";
}

dynem-btn-1 {
  background-color: #004dac;
  color: #ffffff;
  border-radius: 25px;
  padding: 0.5em 1.35em;
  border: none;
}

.dynem-btn-2 {
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 0.5em 1.5em;
  border: none;
}

.faq-landing {
  gap: 5em;
}

.accordion-button {
  font-family: "Poppins-Medium" !important;
}

.faq-landing-1 {
  width: 30%;
}

.faq-landing-2 {
  width: 70%;
}

.accordion {
  --bs-accordion-btn-icon-width: 0.9em;
  --bs-accordion-btn-icon-height: 0.9em;
}

.choose-us {
  background-color: #f9f9ff;
}

.choose-us-inner-div {
  width: 20%;
}

.choose-us-img {
  width: 70%;
}

.why-choose-us p {
  font-size: 1.067em;
}

.flex-item img {
  width: 50%;
}

.flex-wrap-2 .flex-item img {
  width: 40%;
}

.flex-item {
  width: 16%;
}

.oem-div {
  gap: 2em;
}

.news {
  background-color: #f6f6f6;
}

.testimonial-text {
  font-size: 1.4em;
}

.get-quote-banner-button {
  top: 70%;
  left: 10%;
}

th {
  padding: 0.5em;
  padding-left: 1em;
}

.row-1 {
  background-color: #fffbf6;
  font-size: 0.7em;
  padding: 0.5em 1em;
}

.row-2 {
  background-color: #fff9f9;
  font-size: 0.7em;
  padding: 0.5em 1em;
}

.row-3 {
  background-color: #fbfff8;
  font-size: 0.7em;
  padding: 0.5em 1em;
}

.made-in-india {
  overflow: hidden;
  top: 13%;
  left: 45%;
}

.news-heading {
  height: 95px;
}

.contact-us-landing .form-control {
  background: transparent;
  color: white;
}

.contact-us-landing .PhoneInputInput {
  background: transparent;
  color: white;
}

.contact-us-landing .form-control:focus {
  border-color: white !important;
}

.contact-us-landing .form-label {
  font-family: "Poppins-Light";
  font-size: 1em;
}

.ecycle-range-card {
  gap: 1em;
}

.get-quote-landing h5 {
  font-size: 1.1em;
}

.switching p {
  font-size: 0.9em;
}

.inverted-comma {
  top: 15%;
  left: 24%;
}

.inverted-comma img {
  width: 70%;
}

.faq-landing .accordion-button:not(.collapsed) {
  color: #ff0000;
}

.get-quote-banner-button {
  top: 50%;
  left: 7%;
}

@media (max-width: 1400px) {
  .news-heading {
    font-size: 0.9em;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .news-heading {
    font-size: 0.75em;
  }
  .tablet-display-off {
    display: none;
  }
  .tablet-display-on {
    display: block !important;
  }
  .faq-landing-1 {
    width: 40%;
  }

  .faq-landing-2 {
    width: 60%;
  }

  .get-quote-landing h2 {
    font-size: 1.5em;
  }

  .get-quote-landing h4 {
    font-size: 1.2em;
  }

  .large-tablet-screen-on {
    display: none;
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  .news-heading {
    font-size: 0.8em;
  }
  .tablet-display-off {
    display: none !important;
  }
  .large-tablet-screen-on {
    display: block !important;
  }
  .testimonial-text {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 640px) {
  .testimonial-text {
    font-size: 0.7em;
  }
  .ecycle-range-card {
    gap: 0.5em;
    flex-direction: column;
    border: 1px solid #ffc1c1;
    background-color: #fff9f9;
    border-radius: 20px;
  }

  .ecycle-range-car-content {
    height: 100px;
  }

  .component-card-content {
    height: 100px;
  }

  .range-ebikes .splide__slide {
    transform: scale(1);
    opacity: 1;
  }

  .get-quote-banner-button-mb {
    top: 30%;
    left: 6%;
  }

  .get-quote-banner-button-home-mb {
    top: 35%;
    left: 10%;
    transform: translateX(-50%, -50%);
  }

  .get-quote-landing h2 {
    font-size: 1.3em;
  }

  .get-quote-landing h4 {
    font-size: 1.2em;
  }

  .get-quote-landing,
  p {
    font-size: 0.9em;
  }

  .faq-landing {
    flex-direction: column;
    gap: 2em;
  }

  .faq-landing-1,
  .faq-landing-2 {
    width: 100%;
  }

  .req-call {
    display: flex;
    justify-content: center;
  }

  .accordion {
    --bs-accordion-btn-icon-width: 0.9em;
    --bs-accordion-btn-icon-height: 0.9em;
  }

  .accordion-button {
    font-size: 0.8em;
  }

  .flex-item {
    flex: 1 1 calc(25% - 16px);
    text-align: center;
    padding: 10px;
    min-width: calc(50% - 16px);
  }

  .flex-item img {
    width: 35%;
  }

  .flex-wrap-2 .flex-item img {
    width: 25%;
  }

  .switching {
    text-align: center;
  }

  .inverted-comma {
    top: 0%;
    left: 2%;
  }

  .inverted-comma img {
    width: 40%;
  }

  .testimonials h4 {
    font-size: 0.9em;
  }

  .amigo-spec {
    min-width: 80%;
  }
}
