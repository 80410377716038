.contact-us-banner-heading {
  top: 10%;
  left: 4%;
}

.contact-us-banner-heading .banner-text {
  font-size: 1.5em;
}

.contact-us-form {
  background-color: white;
  width: 25%;
  top: 10%;
  right: 4%;
}
.PhoneInputInput {
  padding: 3px;
  border: none !important;
}
.form-control:focus {
  border-color: #ff0000;
  box-shadow: none;
}
.contact-us-submit:hover {
  background-color: #ff0000;
  color: white;
}

contact-us-form-placement {
  top: 10%;
  right: 4%;
}

@media screen and (max-width: 700px) {
  .contact-us-banner-heading .banner-text {
    font-size: 0.75em;
  }
  .contact-us-form {
    width: 100%;
  }
}
