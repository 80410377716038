.banner-heading {
  font-size: 5em;
  color: white;
  text-transform: uppercase;
  font-family: "Metropolis-Bold", sans-serif;
}

.category-banner-heading {
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.listing-content {
  height: 330px;
}

.product-specs p {
  font-size: 0.9em;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1em;
}

.listing-category-cards {
  border: 0.5px solid grey;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1470px) {
  .banner-heading {
    font-size: 4em;
  }
  .listing-content {
    height: 365px;
  }
}

@media screen and (max-width: 1300px) {
  .banner-heading {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 700px) {
  .banner-heading {
    font-size: 2em;
  }
  .grid-container {
    grid-template-columns: auto;
    grid-gap: 1em;
  }
  .category-banner-heading {
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
}
