footer {
  background-color: #151a20;
  color: white;
}

.footer-desc {
  font-size: 0.9em;
  color: #ff0000;
}

.footer-links {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

footer h5 {
  font-size: 0.9em;
  font-weight: 600;
  color: #ff0000;
}

.footer-links a {
  color: white;
  font-size: 0.8em;
  text-decoration: none;
  display: block;
  margin-top: 1em;
}
.footer-bottom p {
  font-size: 0.8em;
}
.footer-bottom a {
  color: white;
  font-size: 0.8em;
  text-decoration: none;
}
