.modal {
  z-index: 10500 !important;
}

.modal-backdrop {
  z-index: 10 !important;
}

.modal-header {
  border: none;
  padding: 0;
}

.modal-body {
  padding: 0;
}
